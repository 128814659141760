import "./init-source-map-consumer";

const elementsToAnimate = document.getElementsByClassName("animate-in");

const animateInElements = () => {
  for (var i = 0; i < elementsToAnimate.length; i++) {
    elementsToAnimate[i].classList.add("visible");
  }
};

export const onClientEntry = () => {
  window.onload = () => {
    animateInElements();
  };

  // animate after delay, because window.onload only seems to work some of the time in Chrome
  setTimeout(() => {
    animateInElements();
  }, 500);

  // document.addEventListener("scroll", (event) => animateInElements(), false);
};

export const onRouteUpdate = () => {
  // animateInElements();

  window.onload = () => {
    animateInElements();
  };

  // animate after delay, because window.onload only seems to work some of the time in Chrome
  setTimeout(() => {
    animateInElements();
  }, 500);

  // document.addEventListener("scroll", (event) => animateInElements(), false);
};
