const SourceMapConsumer = require("source-map").SourceMapConsumer;

if (typeof window !== "undefined") {
  SourceMapConsumer.initialize({
    "lib/mappings.wasm": require("path").join(
      __dirname,
      "node_modules",
      "source-map",
      "lib",
      "mappings.wasm"
    ),
  });
}
